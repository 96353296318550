.amplify-button {
  background-color: #1889db;
  color: white;
  border-radius: 10px;
}
.amplify-input {
  background-color: #f2f2f7;
  color: black;
  border-radius: 12px;
  padding: 8px 10px;
  position: relative;
}
.amplify-alert__dismiss {
  display: none;
}
.amplify-button.amplify-field-group__control.amplify-field__show-password {
  background-color: #f2f2f7;
  color: black;
  /* border-radius: 3px; */
}
[data-amplify-authenticator][data-variation=modal] {

  background-color: #FFF;
}
.amplify-tabs__list--top{

  display: none;
}
[data-amplify-authenticator] [data-amplify-router]{
  border: none;
  box-shadow: none;
}








